import { Currency } from 'data/currencies';
import { FormattedMessage } from 'react-intl';
import {
  BlankValueType,
  DisplayUnitType,
  NegativeValueType,
  ZeroFormatType,
} from 'utils/data-formatter/types';

export const displayUnitOptions = [
  {
    label: <FormattedMessage id="data_format.modal.local_data_options.full_number.label" />,
    value: DisplayUnitType.Full,
    currencies: Object.keys(Currency) as Currency[],
  },
  {
    label: <FormattedMessage id="data_format.modal.local_data_options.lakhs.label" />,
    value: DisplayUnitType.Lac,
    currencies: [Currency.INR],
  },
  {
    label: <FormattedMessage id="data_format.modal.local_data_options.crores.label" />,
    value: DisplayUnitType.Cr,
    currencies: [Currency.INR],
  },
  {
    label: <FormattedMessage id="data_format.modal.local_data_options.thousands.label" />,
    value: DisplayUnitType.K,
    currencies: Object.keys(Currency) as Currency[],
  },
  {
    label: <FormattedMessage id="data_format.modal.local_data_options.millions.label" />,
    value: DisplayUnitType.M,
    currencies: Object.keys(Currency) as Currency[],
  },
  {
    label: <FormattedMessage id="data_format.modal.local_data_options.short_number.label" />,
    value: DisplayUnitType.Short,
    currencies: Object.keys(Currency) as Currency[],
  },
];

export const zeroFormatOptions = [
  {
    label: <FormattedMessage id="data_format.modal.zero_format_options.number.label" />,
    value: ZeroFormatType.Number,
  },
  {
    label: <FormattedMessage id="data_format.modal.zero_format_options.dash.label" />,
    value: ZeroFormatType.Dash,
  },
];

export const negativeValueOptions = [
  {
    label: <FormattedMessage id="data_format.modal.negative_value_options.default.label" />,
    value: NegativeValueType.Sign,
  },
  {
    label: <FormattedMessage id="data_format.modal.negative_value_options.brackets.label" />,
    value: NegativeValueType.Brackets,
  },
];

export const blankValueOptions = [
  {
    label: <FormattedMessage id="data_format.modal.zero_format_options.number.label" />,
    value: BlankValueType.Zero,
  },
  {
    label: <FormattedMessage id="data_format.modal.blank_value_options.blank.label" />,
    value: BlankValueType.Blank,
  },
];
