import { Modal } from 'components/ui/atomic-components';
import { Form } from 'components/ui/atomic-components/form';
import { QueryTableColumnDataType } from 'data/big-query';
import { isEmpty } from 'lodash';
import { useEffect, useState, type ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useListStoreContext } from 'store/lists';
import styled from 'styled-components';
import { getColumnNameFromRawDimensionName } from 'utils/dimensions';
import { UpdateFromDataForm } from './form';
import { useSetupDerivedListRecon } from './hooks/use-setup-derived-list-recon';
import { type FormProps } from './types';
import { setInitialValues } from './utils/set-initial-values';

const StyledModal = styled(Modal)`
  .ant-modal-body {
    max-height: 600px;
    overflow: auto;
  }
`;

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const UpdateFromDataModal = ({ open, setOpen }: Props): ReactElement => {
  const listId = useListStoreContext((s) => s.id);
  const reconConfig = useListStoreContext((s) => s.reconciliationConfig);
  const setupDerivedListReconMutation = useSetupDerivedListRecon();
  const [dimsForm] = Form.useForm<FormProps>();
  const [initialFormValues, setInitialFormValues] = useState<Partial<FormProps>>({});

  const resetForms = () => {
    dimsForm.resetFields();
  };

  const handleSave = async () => {
    const data = await dimsForm.validateFields();

    await setupDerivedListReconMutation.mutateAsync({
      listId,
      request: {
        sourceDatasetColumns: data.dimensions.map((dim) => ({
          source: dim.value,
          type: (dim.columnType as QueryTableColumnDataType) || QueryTableColumnDataType.String,
          colName: dim.name || '',
          tableDisplayName: dim.tableDisplayName || '',
        })),
        uniqueColumns: data.uniqueKeyColumns.map((c) => getColumnNameFromRawDimensionName(c.value)),
        sourceDatasetFilters: data.filters,
      },
    });

    setOpen(false);
    resetForms();
  };

  useEffect(() => {
    if (reconConfig) {
      const formInitialValues = setInitialValues(reconConfig);

      setInitialFormValues(formInitialValues);

      dimsForm.setFieldsValue(formInitialValues);
    }
  }, [reconConfig, dimsForm]);

  return (
    <StyledModal
      cancelText={<FormattedMessage id="cancel" />}
      confirmLoading={setupDerivedListReconMutation.isLoading}
      destroyOnClose
      okText={<FormattedMessage id="save" />}
      open={open}
      title={<FormattedMessage id="lists.reconciliation.modal.title" />}
      onCancel={() => {
        setOpen(false);
        resetForms();
      }}
      onOk={isEmpty(initialFormValues) ? handleSave : undefined}
    >
      <UpdateFromDataForm form={dimsForm} initialValues={initialFormValues} />
    </StyledModal>
  );
};
