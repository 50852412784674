import {
  Decoration,
  MatchDecorator,
  type ViewPlugin,
  WidgetType,
  type EditorView,
} from '@codemirror/view';
import { FormulaBarTypes } from 'components/ui/codemirror-v2/formula-bar/constants';
import { type FormulaAutocompleteOption } from 'components/ui/codemirror-v2/formula-bar/types';
import { type MonitoringMetric } from 'data/metric-builder';
import { type PlanMetric } from 'data/modelling/metric';
import { createRoot } from 'react-dom/client';
import { MetricTag } from '../../tag-components';
import { DeletedMetricTag } from '../../tag-components/metric-tag/deleted-metric-tag';
import { FormulaTermRegexMap } from './constants';
import { metricExpressionUpdator, constructViewPlugin } from './utils';

class MetricConstruct extends WidgetType {
  metricTerm: RegExpExecArray;
  from: number;
  metrics: (PlanMetric | MonitoringMetric)[];
  readOnly?: boolean;
  customTimeAutoCompletionOptions?: FormulaAutocompleteOption[];

  constructor(
    metricTerm: RegExpExecArray,
    from: number,
    metrics: (PlanMetric | MonitoringMetric)[],
    readOnly?: boolean,
    customTimeAutoCompletionOptions?: FormulaAutocompleteOption[],
  ) {
    super();
    this.metricTerm = metricTerm;
    this.from = from;
    this.metrics = metrics;
    this.readOnly = readOnly;
    this.customTimeAutoCompletionOptions = customTimeAutoCompletionOptions;
  }

  toDOM(view: EditorView) {
    const wrapper = document.createElement('span');

    const metricName = this.metricTerm[1];
    const metric = (this.metrics as Array<PlanMetric | MonitoringMetric>).find(
      (metric) => metric.name === metricName,
    );
    const wrapperRoot = createRoot(wrapper);

    if (!metric) {
      wrapperRoot.render(
        <DeletedMetricTag>{this.metricTerm[1].replace('metrics.', '')}</DeletedMetricTag>,
      );

      return wrapper;
    }

    const updator = metricExpressionUpdator({ view, metricTerm: this.metricTerm, from: this.from });

    wrapperRoot.render(
      <MetricTag
        customTimeAutoCompletionOptions={this.customTimeAutoCompletionOptions}
        metric={metric}
        metricTerm={this.metricTerm}
        readOnly={this.readOnly}
        updateEditorView={updator}
      />,
    );

    return wrapper;
  }
}

export const constructMetricMatchPlugin = (
  metrics: (PlanMetric | MonitoringMetric)[],
  readOnly?: boolean,
  customTimeAutoCompletionOptions?: FormulaAutocompleteOption[],
): ViewPlugin<object> => {
  const matchDecorator = new MatchDecorator({
    regexp: FormulaTermRegexMap[FormulaBarTypes.MetricExpression],
    decoration: (match, view, from) => {
      return Decoration.replace({
        widget: new MetricConstruct(
          match,
          from,
          metrics,
          readOnly,
          customTimeAutoCompletionOptions,
        ),
      });
    },
  });

  return constructViewPlugin(matchDecorator);
};
