import { ReactComponent as DimensionIcon } from 'assets/v2/cube.svg';
import { ReactComponent as DatasetIcon } from 'assets/v2/database-12.svg';
import { ReactComponent as ListIcon } from 'assets/v2/menu-2.svg';
import { useCurrency } from 'data/currencies/hooks/use-currency';
import { DimensionAggregations, TimeAggregations } from 'data/metrics/aggregations';
import { isEmpty, keyBy } from 'lodash';
import { type ReactElement, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { numberFormatter } from 'utils/data-formatter';
import { DataFormattingType } from 'utils/data-formatter/types';
import { useUser } from 'utils/hooks';
import { BackButton, Spinner } from '../atomic-components';
import { useFormulaEditorMetrics } from '../atomic-components/formula-editor-v2/hooks/use-formula-editor-metrics';
import { ReadOnlyFormula } from '../atomic-components/formula-editor-v2/readonly-formula';
import { Grid } from './grid';
import { useFormulaTraceStore } from './store';
import { styles } from './styles';
import { getDimsLabelForCell } from './utils/get-dims-label';

const {
  ContentWrapper,
  CalculationInfoItem,
  CalculationInfoTitle,
  CalculationInfoContent,
  CalculationInfoWrapper,
  ValueWrapper,
  ValueItem,
  ValueInfoLabel,
  FormulaContainer,
  ComponentBreakup,
  BackButtonWrapper,
  DebugViewWrapper,
  DebugInfoButton,
} = styles;

export const TraceContent = (): ReactElement => {
  const domain = 'all';
  const traceNode = useFormulaTraceStore((state) => state.traceNode);
  const setTraceNode = useFormulaTraceStore((state) => state.setTraceNode);
  const [showDebugInfo, setShowDebugInfo] = useState(false);

  const { isDtUser } = useUser();

  const { data: metrics, isLoading: isMetricsLoading } = useFormulaEditorMetrics(domain);

  const currency = useCurrency();

  const metricsLookup = useMemo(() => {
    if (metrics) {
      return keyBy(metrics, 'name');
    }

    return {};
  }, [metrics]);

  const value = useMemo(() => {
    let value = traceNode?.term?.value;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const type = (
      metricsLookup?.[traceNode?.term?.metricName || ''] as { type: DataFormattingType }
    )?.type;

    if (value) {
      value = numberFormatter({
        value: +value,
        type: DataFormattingType.Number,
        digitsAfterDecimal: 2,
        currency,
      });
    }

    return value;
  }, [currency, metricsLookup, traceNode?.term?.metricName, traceNode?.term?.value]);

  const metricName = traceNode?.term?.metricDisplayName;

  const dimsLabel = useMemo(() => {
    if (!traceNode?.term) {
      return '';
    }

    return getDimsLabelForCell(
      traceNode?.term,
      traceNode?.payload?.resourceInfo?.requestSource !== 'MMPP',
    );
  }, [traceNode?.payload?.resourceInfo?.requestSource, traceNode?.term]);

  const terms = traceNode?.term?.terms || [];

  const dimSummaryLabel = useMemo(
    () =>
      DimensionAggregations.find((agg) => agg.value === traceNode?.term?.dimensionSummary)?.label,
    [traceNode?.term?.dimensionSummary],
  );

  const timeSummaryLabel = useMemo(
    () => TimeAggregations.find((agg) => agg.value === traceNode?.term?.timeSummary)?.label,
    [traceNode?.term?.timeSummary],
  );

  let fromSourceLabel = '';
  let fromSourceIcon = undefined;
  const fromSourceLabelValue = traceNode?.term?.sourceDisplayName;

  if (traceNode?.term?.fromDataSource === 'list') {
    fromSourceLabel = 'List';
    fromSourceIcon = ListIcon;
  } else if (traceNode?.term?.fromDataSource === 'dataset') {
    fromSourceLabel = 'Dataset';
    fromSourceIcon = DatasetIcon;
  }

  const isLoading = isMetricsLoading || traceNode?.isLoading;

  return (
    <ContentWrapper>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <ValueWrapper>
            {traceNode?.previousNode && (
              <BackButtonWrapper>
                <BackButton
                  size="small"
                  onClick={() => traceNode?.previousNode && setTraceNode(traceNode?.previousNode)}
                >
                  <FormattedMessage id="back" />
                </BackButton>
              </BackButtonWrapper>
            )}
            <ValueItem>{value}</ValueItem>
            {metricName && (
              <ValueInfoLabel>
                <FormattedMessage
                  id="formula_trace.value_dims_info.label"
                  values={{ metric: <b>{metricName}</b>, dims: dimsLabel }}
                />
              </ValueInfoLabel>
            )}
          </ValueWrapper>

          <CalculationInfoWrapper>
            <CalculationInfoTitle>
              <FormattedMessage id="formula_trace.calculation_info.title" />
            </CalculationInfoTitle>
            <CalculationInfoContent>
              {dimSummaryLabel && (
                <CalculationInfoItem
                  icon={DimensionIcon}
                  text={
                    <>
                      <FormattedMessage id="planning.metric.dimension_aggregation" />
                      {': '}
                      <b>{dimSummaryLabel}</b>
                    </>
                  }
                />
              )}
              {timeSummaryLabel && (
                <CalculationInfoItem
                  icon={DimensionIcon}
                  text={
                    <>
                      <FormattedMessage id="planning.metric.time_aggregation" />
                      {': '}
                      <b>{timeSummaryLabel}</b>
                    </>
                  }
                />
              )}
              {fromSourceLabel && fromSourceIcon && (
                <CalculationInfoItem
                  icon={fromSourceIcon}
                  text={
                    <>
                      {fromSourceLabel}
                      {': '} <b>{fromSourceLabelValue}</b>
                    </>
                  }
                />
              )}

              {traceNode?.term?.formula &&
                !fromSourceLabel &&
                !dimSummaryLabel &&
                !timeSummaryLabel && (
                  <FormulaContainer>
                    <ReadOnlyFormula
                      domain={domain}
                      grayOut={false}
                      value={traceNode?.term?.formula}
                    />
                  </FormulaContainer>
                )}
            </CalculationInfoContent>
          </CalculationInfoWrapper>

          {traceNode?.term && !isEmpty(terms) && (
            <ComponentBreakup>
              <Grid
                isLoading={traceNode?.isLoading || false}
                parentTerm={traceNode.term}
                terms={terms}
              />
            </ComponentBreakup>
          )}

          {isDtUser && traceNode?.term?.debug && (
            <>
              <DebugInfoButton size="small" type="text" onClick={() => setShowDebugInfo((s) => !s)}>
                {'Debug info '}
                {showDebugInfo ? '▼' : '►'}
              </DebugInfoButton>
              {showDebugInfo && (
                <DebugViewWrapper>
                  <pre>{JSON.stringify(traceNode?.term?.debug, undefined, 4)}</pre>
                </DebugViewWrapper>
              )}
            </>
          )}
        </>
      )}
    </ContentWrapper>
  );
};
