export interface MathFunction {
  id: string;
  displayText: string;
  text: string;
}

export const MathFunctionPrefix = 'func';

export const MathFunctions: MathFunction[] = [
  {
    id: 'ceil',
    displayText: 'Ceil ()',
    text: 'ceil',
  },
  {
    id: 'floor',
    displayText: 'Floor ()',
    text: 'floor',
  },
  {
    id: 'round',
    displayText: 'Round ()',
    text: 'round',
  },
  {
    id: 'rounddown',
    displayText: 'Rounddown ()',
    text: 'rounddown',
  },
  {
    id: 'roundup',
    displayText: 'Roundup ()',
    text: 'roundup',
  },
  {
    id: 'min',
    displayText: 'Min ()',
    text: 'min',
  },
  {
    id: 'max',
    displayText: 'Max ()',
    text: 'max',
  },
  {
    id: 'if',
    displayText: 'If ()',
    text: 'if',
  },
  {
    id: 'date',
    displayText: 'Date ()',
    text: 'date',
  },
  {
    id: 'datediff',
    displayText: 'Datediff ()',
    text: 'datediff',
  },
  {
    id: 'dateadd',
    displayText: 'Dateadd ()',
    text: 'dateadd',
  },
  {
    id: 'today',
    displayText: 'Today ()',
    text: 'today',
  },
  {
    id: 'day',
    displayText: 'Day ()',
    text: 'day',
  },
  {
    id: 'month',
    displayText: 'Month ()',
    text: 'month',
  },
  {
    id: 'year',
    displayText: 'Year ()',
    text: 'year',
  },
  {
    id: 'equals',
    displayText: 'Equals ()',
    text: 'equals',
  },
  {
    id: 'even',
    displayText: 'Even ()',
    text: 'even',
  },
  {
    id: 'odd',
    displayText: 'Odd ()',
    text: 'odd',
  },
  {
    id: 'isblank',
    displayText: 'isBlank ()',
    text: 'isblank',
  },
  {
    id: 'forecast',
    displayText: 'Forecast ()',
    text: 'forecast',
  },
  {
    id: 'average',
    displayText: 'Average ()',
    text: 'average',
  },
  {
    id: 'summarize',
    displayText: 'Summarize ()',
    text: 'summarize',
  },
  {
    id: 'collapse',
    displayText: 'Collapse ()',
    text: 'collapse',
  },
  {
    id: 'mod',
    displayText: 'Mod ()',
    text: 'mod',
  },
  {
    id: 'eomonth',
    displayText: 'EOMonth ()',
    text: 'eomonth',
  },
  {
    id: 'bomonth',
    displayText: 'BOMonth ()',
    text: 'bomonth',
  },
  {
    id: 'datevalue',
    displayText: 'DateValue ()',
    text: 'datevalue',
  },
  {
    id: 'and',
    displayText: 'And ()',
    text: 'and',
  },
  {
    id: 'or',
    displayText: 'Or ()',
    text: 'or',
  },
  {
    id: 'not',
    displayText: 'Not ()',
    text: 'not',
  },
  {
    id: 'ai_forecast ()',
    displayText: 'AI_Forecast',
    text: 'ai_forecast',
  },
  {
    id: 'sum',
    displayText: 'Sum ()',
    text: 'sum',
  },
  {
    id: 'blank',
    displayText: 'Blank ()',
    text: 'blank',
  },
  {
    id: 'CONCAT',
    displayText: 'Concat ()',
    text: 'CONCAT',
  },
  {
    id: 'CONCAT_DEFAULT',
    displayText: 'Concat default ()',
    text: 'CONCAT_DEFAULT',
  },
  {
    id: 'netWorkdays',
    displayText: 'Net working days ()',
    text: 'netWorkdays',
  },
  { id: 'abs', displayText: 'Abs ()', text: 'abs' },
  { id: 'allocate', displayText: 'Allocate ()', text: 'allocate' },
  { id: 'prorata', displayText: 'Prorata ()', text: 'prorata' },
];

export const MathFunctionPrefixMapping: Record<string, string> = {
  ceil: MathFunctionPrefix,
  floor: MathFunctionPrefix,
  round: MathFunctionPrefix,
  roundup: MathFunctionPrefix,
  rounddown: MathFunctionPrefix,
  min: MathFunctionPrefix,
  max: MathFunctionPrefix,
  if: MathFunctionPrefix,
  date: MathFunctionPrefix,
  datediff: MathFunctionPrefix,
  dateadd: MathFunctionPrefix,
  today: MathFunctionPrefix,
  day: MathFunctionPrefix,
  month: MathFunctionPrefix,
  year: MathFunctionPrefix,
  equals: MathFunctionPrefix,
  even: MathFunctionPrefix,
  odd: MathFunctionPrefix,
  isblank: MathFunctionPrefix,
  forecast: MathFunctionPrefix,
  average: MathFunctionPrefix,
  summarize: MathFunctionPrefix,
  collapse: MathFunctionPrefix,
  mod: MathFunctionPrefix,
  eomonth: MathFunctionPrefix,
  bomonth: MathFunctionPrefix,
  datevalue: MathFunctionPrefix,
  and: MathFunctionPrefix,
  or: MathFunctionPrefix,
  not: MathFunctionPrefix,
  ai_forecast: MathFunctionPrefix,
  sum: MathFunctionPrefix,
  blank: MathFunctionPrefix,
  netWorkdays: MathFunctionPrefix,
  abs: MathFunctionPrefix,
  allocate: MathFunctionPrefix,
  prorata: MathFunctionPrefix,
};

export const MathFunctionUsageMapping: Record<
  string,
  { syntax: string; desc: string; ex?: string }
> = {
  ceil: {
    syntax: 'CEIL ( value )',
    desc: 'Rounds up to the nearest whole number.',
    ex: 'CEIL ( 23.2 )',
  },
  floor: {
    syntax: 'FLOOR ( value )',
    desc: 'Rounds down to the nearest whole number.',
    ex: 'FLOOR ( 23.9 )',
  },
  round: {
    syntax: 'ROUND ( value , num_digits )',
    desc: 'Rounds to the nearest whole number. If the fractional portion of the argument is greater than or equal to 0.5, the argument is rounded to the integer with the next higher absolute value. Otherwise, the argument is rounded to the integer with the lower absolute value.',
    ex: 'ROUND ( 41.5 )',
  },
  roundup: {
    syntax: 'ROUNDUP ( value , num_digits )',
    desc: 'Rounds a number up.',
    ex: 'ROUNDUP ( 41.2 )',
  },
  rounddown: {
    syntax: 'ROUNDDOWN ( value , num_digits )',
    desc: 'Rounds a number down.',
    ex: 'ROUNDDOWN ( 41.6 )',
  },
  min: {
    syntax: 'MIN ( value1 , value2 )',
    desc: 'Returns the smallest of the two values.',
    ex: 'MIN ( 23 , -10 )',
  },
  max: {
    syntax: 'MAX ( value1 , value2 )',
    desc: 'Returns the largest of the two values.',
    ex: 'MAX ( 23 , 10 )',
  },
  if: {
    syntax: 'IF ( condition , value_if_true , value_if_false )',
    desc: 'The if formula is used to check whether a condition is met, and returns one value if true and another value if false.',
    ex: 'IF ( DATE ( 2030 , 01 , 01 ) > TODAY () , 2 , 0 )',
  },
  date: {
    syntax: 'DATE ( year , month , day )',
    desc: 'Converts numbers yyyy, mm, and dd to a date. The format returned is yyyy-mm-dd.',
    ex: 'DATE ( 2023 , 2 , 3 )',
  },
  datediff: {
    syntax: 'DATEDIFF ( start_date , end_date , interval )',
    desc: 'Returns the difference between two dates in the units (days, months, or years) requested.',
    ex: "DATEDIFF ( DATE ( 2023 , 1 , 1 ) , DATE ( 2023 , 12 , 31 ) , 'M' )",
  },
  dateadd: {
    syntax: 'DATEADD ( date , interval , interval_type )',
    desc: "Returns a date to which a specified time interval has been added. \n interval_type can take values 'D' / 'DAY' / 'M' / 'MONTH' / 'Y' / 'YEAR'.",
    ex: "DATEADD ( DATE ( 2023 , 1 , 1 ) , 1 , 'M' )",
  },
  today: { syntax: 'TODAY ( )', desc: 'Returns today’s date.' },
  day: {
    syntax: 'DAY ( date )',
    desc: 'Returns the day as a number between 1 and 31.',
    ex: 'DAY ( DATE ( 2023 , 12 , 31 ) )',
  },
  month: {
    syntax: 'MONTH ( date )',
    desc: 'Returns the month as a number between 1 and 12.',
    ex: 'MONTH ( DATE ( 2023 , 12 , 31 ) )',
  },
  year: {
    syntax: 'YEAR ( date )',
    desc: 'Returns the year',
    ex: 'YEAR ( DATE ( 2023 , 2 , 3 ) )',
  },
  equals: {
    syntax: 'equals ( value1 , value2 )',
    desc: 'Checks whether the two operands are equal, and returns 1 if true and 0 if false.',
    ex: 'EQUALS ( 1 , 2 )',
  },
  even: {
    syntax: 'EVEN ( value )',
    desc: 'Returns number rounded up to the nearest even integer. Regardless of the sign of number, a value is rounded up when adjusted away from zero.',
    ex: 'EVEN ( 1.4 )',
  },
  odd: {
    syntax: 'ODD ( value )',
    desc: 'Returns number rounded up to the nearest odd integer. Regardless of the sign of number, a value is rounded up when adjusted away from zero.',
    ex: 'ODD ( 1.4 )',
  },
  isblank: {
    syntax: 'ISBLANK ( value )',
    desc: 'Checks whether the given value is blank or missing or undefined or encountered an error. Returns TRUE or FALSE. ',
  },
  //: unique syntax name: 'unique ( dimension , unique_column , time_column )', desc: ''},
  forecast: {
    syntax: "FORECAST ( metric_or_variable , 'type_of_forecast' , start_index , end_index )",
    desc: 'Finds the best fit line for the given data by using the linear regression method to forecast values for the time period specified.',
    ex: "FORECAST ( Expansion ARR , 'linear' , 0 , 6 )",
  },
  average: {
    syntax: 'AVERAGE ( metric_or_variable , start_index , end_index )',
    desc: 'Calculates simple average or moving average for a metric or a variable',
    ex: 'AVERAGE ( Customers , This Month - 3 , This Month - 1)',
  },
  summarize: {
    syntax: 'SUMMARIZE ( metric_or_variable , dimension1 , dimension2 , ... )',
    desc: 'Aggregates the values in a metric by its aggregation summary type (sum, average or formula) across the specified dimensions. In simple terms, the function reduces the number of rows by applying some aggregation.',
    ex: 'SUMMARIZE ( Revenue , Country )',
  },
  collapse: {
    syntax: 'COLLAPSE ( metric_or_variable , dimension1 , dimension2 , ... )',
    desc: 'Aggregates the values in a metric by its aggregation summary type (sum, average or formula) after collapsing the specified dimensions and aggregating across the rest. ',
    ex: 'COLLAPSE ( Revenue , Region , Territory )',
  },
  mod: {
    syntax: 'MOD ( dividend , divisor )',
    desc: 'Returns the remainder (an integer value) after dividing the first number by the second number',
    ex: 'MOD ( 8 , 3 )',
  },
  eomonth: {
    syntax: 'EOMONTH ( start_date , offset_in_months )',
    desc: 'Returns the date in yyyy-mm-dd format of the last day of the month, before or after a specified number of months.',
    ex: "EOMONTH ( '2023-09-20' , 3)",
  },
  bomonth: {
    syntax: 'BOMONTH ( start_date , offset_in_months )',
    desc: 'Calculates the date in yyyy-mm-dd format of the first day of the month, a specified number of months before or after the specified date.',
    ex: "BOMONTH ( '2023-09-20' , 3)",
  },
  datevalue: {
    syntax: 'DATEVALUE ( date_text )',
    desc: 'Returns a date in yyyy-mm-dd format format.',
    ex: "DATEVALUE ( '01/02/23' )",
  },
  and: {
    syntax: 'AND ( logical_expression_1 , logical_expression_2 )',
    desc: 'Returns true if both arguments are logically true, and returns false if either of the provided arguments are logically false. The And function takes in two arguments and both are required. To evaluate more than two logical expressions at a time, you can nest multiple And functions.',
    ex: 'AND ( true , true )',
  },
  or: {
    syntax: 'OR ( logical_expression_1 , logical_expression_2 )',
    desc: 'Returns true, if one of the arguments is true and returns false if both arguments are false. It takes in two arguments and both are required. To evaluate more than two logical expressions at a time, you can nest multiple Or functions.',
    ex: 'OR ( true , true )',
  },
  not: {
    syntax: 'NOT ( logical_expression )',
    desc: 'Returns the inverted value or expression passed to it. If false, then returns true and vice versa',
    ex: 'NOT ( true )',
  },

  sum: {
    syntax: 'SUM ( metric_or_variable , start_index , end_index )',
    desc: 'Calculates the sum across the months specified for a metric or a variable',
    ex: 'SUM ( Customers , This Month - 3 , This Month - 1)',
  },
  ai_forecast: { syntax: 'AI_FORECAST ( )', desc: '' },
  blank: { syntax: 'BLANK ( )', desc: '' },
  CONCAT: {
    syntax:
      'CONCAT ( dimension_or_single_quoted_string_1 , dimension_or_single_quoted_string_2 , ... )',
    desc: 'Concatenates text from multiple columns',
    ex: "CONCAT ( 'Hello' , 'World' )",
  },
  CONCAT_DEFAULT: {
    syntax:
      'CONCAT_DEFAULT ( dimension_or_single_quoted_string_1 , dimension_or_single_quoted_string_2 , ... )',
    desc: "Concatenates text from multiple columns with '-' as the separator",
    ex: "CONCAT_DEFAULT ( 'Hello' , 'World' )",
  },
  netWorkdays: {
    syntax: 'NETWORKDAYS ( start_date , end_date )',
    desc: 'Returns the number of whole working days between start_date and end_date',
    ex: 'NETWORKDAYS ( DATE ( 2023 , 01 , 01 ) , DATE ( 2023 , 12 , 31 ) )',
  },
  LOOKUP: {
    syntax: 'LOOKUP ( result_column_name , search_column_name == search_value )',
    desc: 'Returns the first value from result_column_name for the row where search_column_name matches the search_value',
    ex: 'LOOKUP ( Currency , Currency == USD )',
  },
  abs: {
    syntax: 'ABS ( value )',
    desc: 'Returns the absolute value of a number. The absolute value of a number is the number without its sign.',
    ex: 'ABS ( -2 )',
  },
  allocate: {
    syntax: 'ALLOCATE ( metric_to_allocate , allocation_metric , dimension )',
    desc: 'Helps distribute metric values such as rent, COGS, etc. across different dimensions (such as department, customer, etc.)',
    ex: 'ALLOCATE ( GL Data Metric [Item = Rent] , Headcount By Department , Department )',
  },
  prorata: {
    syntax: 'PRORATA ( start_date , end_date )',
    desc: 'Calculates the prorated ratio of days between dates for each month.',
    ex: "PRORATA( '08-03-2024' , '15-07-2024' )",
  },
};

export const PeriodOptions = [
  {
    id: 't-1',
    label: 'Previous month',
    value: 't-1',
  },
  {
    id: 't',
    label: 'This month',
    value: 't',
  },
  {
    id: 't+1',
    label: 'Next month',
    value: 't+1',
  },
  {
    id: 'quarter',
    label: 'Quarter',
    children: [
      {
        id: 'q-1',
        label: 'Previous quarter',
        value: 'q-1',
      },
      {
        id: 'q',
        label: 'Current quarter',
        value: 'q',
      },
      {
        id: 'q+1',
        label: 'Next quarter',
        value: 'q+1',
      },
    ],
  },
  {
    id: 'year',
    label: 'Year',
    children: [
      {
        id: 'y-1',
        label: 'Previous year',
        value: 'y-1',
      },
      {
        id: 'y',
        label: 'Current year',
        value: 'y',
      },
      {
        id: 'y+1',
        label: 'Next year',
        value: 'y+1',
      },
    ],
  },
  {
    id: 'lastActualsDate',
    label: 'Last actuals date',
    value: 'time.moduleActualsEndDate',
  },
];

export const DefaultPeriodExpr = 't';
export const DefaultTimelessPeriodExpr = '0';
export const PeriodTerms = [DefaultPeriodExpr, 'q', 'y'];

export const FORMULA_COLUMN_ID = 'model-grid-forecast-formula';
export const TABULAR_DIMENSION_COL_ID = 'model-grid-tabular-dimension-col';
