import { useIntl } from 'react-intl';
import { CurrencyDimensionName, TimeDimensionName } from './constants';
import { type Dimension } from './types';

export const useTimeDimension = (): Dimension => {
  const intl = useIntl();

  return {
    name: TimeDimensionName,
    displayName: intl.formatMessage({ id: 'time' }),
    allowed: true,
  } as Dimension;
};

export const useCurrencyAsDimension = (): Dimension => {
  const intl = useIntl();

  return {
    name: CurrencyDimensionName,
    displayName: intl.formatMessage({ id: 'currency' }),
    allowed: true,
  } as Dimension;
};
