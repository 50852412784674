import { TimeAggregationTypes } from 'data/modelling/metric';
import { FormattedMessage } from 'react-intl';

export const TimeAggregations = [
  {
    label: <FormattedMessage id="planning.metric.time_aggregation.sum" />,
    description: <FormattedMessage id="planning.metric.time_aggregation.sum_desc" />,
    value: TimeAggregationTypes.Sum,
  },
  {
    label: <FormattedMessage id="planning.metric.time_aggregation.average" />,
    description: <FormattedMessage id="planning.metric.time_aggregation.average_desc" />,
    value: TimeAggregationTypes.Average,
  },
  {
    label: <FormattedMessage id="planning.metric.time_aggregation.bop" />,
    description: <FormattedMessage id="planning.metric.time_aggregation.bop_desc" />,
    value: TimeAggregationTypes.BeginningOfPeriod,
  },
  {
    label: <FormattedMessage id="planning.metric.time_aggregation.eop" />,
    description: <FormattedMessage id="planning.metric.time_aggregation.eop_desc" />,
    value: TimeAggregationTypes.EndOfPeriod,
  },
  {
    label: <FormattedMessage id="planning.metric.time_aggregation.none" />,
    description: <FormattedMessage id="planning.metric.time_aggregation.none_desc" />,
    value: TimeAggregationTypes.None,
  },
  {
    label: <FormattedMessage id="planning.metric.time_aggregation.constant" />,
    description: <FormattedMessage id="planning.metric.time_aggregation.constant_desc" />,
    value: TimeAggregationTypes.Constant,
  },
  {
    label: <FormattedMessage id="planning.metric.time_aggregation.formula.ellipsis" />,
    description: <FormattedMessage id="planning.metric.time_aggregation.formula_desc" />,
    value: TimeAggregationTypes.Formula,
  },
];
