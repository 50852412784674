import { DimensionAggregationTypes } from 'data/modelling/metric';
import { FormattedMessage } from 'react-intl';

export const DimensionAggregations = [
  {
    label: <FormattedMessage id="planning.metric.dimension_aggregation.sum" />,
    description: <FormattedMessage id="planning.metric.dimension_aggregation.sum_desc" />,
    value: DimensionAggregationTypes.Sum,
  },
  {
    label: <FormattedMessage id="planning.metric.dimension_aggregation.average" />,
    description: <FormattedMessage id="planning.metric.dimension_aggregation.average_desc" />,
    value: DimensionAggregationTypes.Average,
  },
  {
    label: <FormattedMessage id="planning.metric.dimension_aggregation.none" />,
    description: <FormattedMessage id="planning.metric.dimension_aggregation.none_desc" />,
    value: DimensionAggregationTypes.None,
  },
  {
    label: <FormattedMessage id="planning.metric.dimension_aggregation.formula.ellipsis" />,
    description: <FormattedMessage id="planning.metric.dimension_aggregation.formula_desc" />,
    value: DimensionAggregationTypes.Formula,
  },
];
