import { type InputRef, type FormInstance } from 'antd';
import { ReactComponent as KeyIcon } from 'assets/v2/key.svg';
import { useChipColoring } from 'components/modules/modelling/_commons/dimension-chip-coloring/use-chip-coloring';
import {
  AsyncDimensionsSelect,
  type DimensionSelectOption,
} from 'components/modules/modelling/modules/detail-v2/table/cell-components/metric-cell/metric-dimension-popover/dimensions-select';
import { MultiLabelComponent } from 'components/modules/modelling/modules/detail-v2/table/cell-components/metric-cell/metric-dimension-popover/dimensions-select/multi-label-component';
import { OptionComponent } from 'components/modules/modelling/modules/detail-v2/table/cell-components/metric-cell/metric-dimension-popover/dimensions-select/option-component';
import { Form, IconText, Input, Select } from 'components/ui/atomic-components';
import { ColumnBasedFilters } from 'components/ui/column-based-filters';
import { type DimensionGroup } from 'data/modelling/dimension-group/types';
import { isEmpty } from 'lodash';
import { useMemo, type ReactElement, useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { type MultiValue } from 'react-select';
import styled from 'styled-components';
import { defaultTheme } from 'styles/theme';
import { formatName } from 'utils/data-formatter';
import { DuplicateRowsData } from './duplicate-rows-data';
import { type FormProps } from './types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing[16]};
  overflow: auto;
`;

const Label = styled.div`
  color: ${({ theme }) => theme.colors.black400};
  font-size: ${({ theme }) => theme.fontSize.caption};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  margin-bottom: ${({ theme }) => theme.spacing[4]};
`;

const StyledColumnBasedFilters = styled(ColumnBasedFilters)`
  border-top: 1px solid ${({ theme }) => theme.colors.gray200};

  .column-based-filters-header-item {
    padding: 0;
  }

  .column-based-filters-rule-card-item {
    max-width: 450px;
  }
`;

const columnOptionMapper = (option: DimensionSelectOption) => ({
  value: option.value,
  label: formatName(option.name),
  parentSourceDisplayName: option.parentSourceDisplayName,
});

interface Props {
  form: FormInstance<FormProps>;
  initialValues?: Partial<FormProps>;
  editMode?: boolean;
  className?: string;
  duplicatesData?: DimensionGroup;
}

export const DimensionGroupCreateEditForm = ({
  form,
  initialValues,
  editMode,
  className,
  duplicatesData,
}: Props): ReactElement => {
  const groupDimensions = Form.useWatch('groupDimensions', form);

  const dims = useMemo(() => groupDimensions?.map((d) => d.value) || [], [groupDimensions]);
  const chipColoringScheme = useChipColoring({ dims });

  const inputRef = useRef<InputRef>(null);

  const uniqueKeyOptions = (groupDimensions || []).map(columnOptionMapper);

  const onGroupDimensionsChange = (newValue: MultiValue<DimensionSelectOption>) => {
    const dims = newValue?.map((d) => d.value) || [];

    const uniqueKeyColumns = form
      .getFieldValue('uniqueKeyColumns')
      ?.filter((column: { value: string }) => {
        return dims.includes(column.value);
      });

    form.setFieldsValue({ groupDimensions: newValue as DimensionSelectOption[], uniqueKeyColumns });
  };

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 10);
  }, []);

  return (
    <Form form={form} initialValues={initialValues}>
      <Wrapper className={className}>
        <div>
          <Label>
            <FormattedMessage id="lists.name" />
          </Label>
          <Form.Item name="groupName" noStyle>
            <Input ref={inputRef} disabled={editMode} size="medium" />
          </Form.Item>
        </div>

        <div>
          <Label>
            <FormattedMessage id="dimension_groups.create_edit_form.select_dimensions.label" />
          </Label>
          <Form.Item name="groupDimensions" noStyle>
            <AsyncDimensionsSelect
              chipColoringScheme={chipColoringScheme}
              groupRelatedResults
              isMulti
              preventDimensionGroupSearch
              searchAllTables
              onChange={onGroupDimensionsChange}
            />
          </Form.Item>
        </div>

        <div>
          <Label>
            <IconText
              gap={4}
              icon={KeyIcon}
              text={
                <FormattedMessage id="dimension_groups.create_edit_form.set_unique_key.label" />
              }
            />
          </Label>
          <Form.Item name="uniqueKeyColumns" noStyle>
            <Select
              components={{ Option: OptionComponent, MultiValueLabel: MultiLabelComponent }}
              isClearable={false}
              isDisabled={groupDimensions?.length === 0}
              isMulti
              isSearchable
              menuPortalTarget={document.body}
              options={uniqueKeyOptions}
              size="large"
              styles={{
                multiValue: (base, props) => {
                  const { background, border } = chipColoringScheme(props.data.value);

                  return {
                    ...base,
                    backgroundColor: background,
                    border: `1px solid ${border}`,
                    borderRadius: defaultTheme.borderRadius.xs,
                  };
                },
              }}
            />
          </Form.Item>
        </div>

        <div>
          <Form.Item name="filters" noStyle>
            <StyledColumnBasedFilters
              selectedDimensions={
                groupDimensions?.map(({ value }) => value)?.filter((dim) => dim) || []
              }
            />
          </Form.Item>
        </div>

        {!isEmpty(duplicatesData) && <DuplicateRowsData data={duplicatesData} />}
      </Wrapper>
    </Form>
  );
};
